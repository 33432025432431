import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Тренажерний зал Олімп
			</title>
			<meta name={"description"} content={"Ласкаво просимо до тренажерного залу Олімп, місця, де фітнес-прагнення перетворюються на реальність. Наш тренажерний зал поєднує в собі найсучасніше обладнання з підтримуючою та енергійною атмосферою, створеною для того, щоб зробити ваш фітнес-подорож більш захоплюючою."} />
			<meta property={"og:title"} content={"Головна | Тренажерний зал Олімп"} />
			<meta property={"og:description"} content={"Ласкаво просимо до тренажерного залу Олімп, місця, де фітнес-прагнення перетворюються на реальність. Наш тренажерний зал поєднує в собі найсучасніше обладнання з підтримуючою та енергійною атмосферою, створеною для того, щоб зробити ваш фітнес-подорож більш захоплюючою."} />
			<meta property={"og:image"} content={"https://eretancols.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://eretancols.com/img/6750831.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://eretancols.com/img/6750831.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://eretancols.com/img/6750831.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://eretancols.com/img/6750831.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://eretancols.com/img/6750831.png"} />
			<meta name={"msapplication-TileImage"} content={"https://eretancols.com/img/6750831.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://eretancols.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Тренажерний зал Олімп
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Латишева 4а, Полтава
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 110px 0" quarkly-title="List-1">
			<Text margin="0px 0px 15px 0px" text-align="center" font="normal 600 64px/1.2 --fontFamily-sans" sm-font="normal 600 56px/1.2 --fontFamily-sans">
				Чому варто обрати нас?
			</Text>
			<Text
				margin="0px 0px 90px 0px"
				text-align="center"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#5e646a"
				md-margin="0px 0px 60px 0px"
			>
				Ласкаво просимо до тренажерного залу Олімп, місця, де фітнес-прагнення перетворюються на реальність. Наш тренажерний зал поєднує в собі найсучасніше обладнання з підтримуючою та енергійною атмосферою, створеною для того, щоб зробити ваш фітнес-подорож більш захоплюючою. Від важкої атлетики до кардіо, від групових занять до персональних тренувань - у нас є все необхідне для досягнення ваших цілей у сфері здоров'я та фітнесу.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 40px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
						Персоналізовані програми тренувань
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
						Наші сертифіковані тренери розробляють індивідуальні програми тренувань, гарантуючи, що ви отримаєте найефективніший та найбезпечніший досвід занять фітнесом.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
						Найсучасніше обладнання
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
						Ми пишаємося тим, що надаємо широкий вибір високоякісних, доглянутих тренажерів, які підходять як для початківців, так і для професійних спортсменів.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
						Орієнтація на громаду
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
						У Олімп ви не просто член клубу - ви є частиною спільноти. Наша атмосфера підтримки сприяє мотивації та довготривалій дружбі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
						Гнучкі варіанти членства
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
						Ми пропонуємо різні плани членства, які відповідають вашому стилю життя та бюджету, без прихованих платежів та довгострокових зобов'язань.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-15">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 35px 0px"
					sm-flex-direction="column"
				>
					<Image
						src="https://eretancols.com/img/2.jpg"
						display="block"
						object-fit="cover"
						margin="0px 5px 0px 0px"
						width="26%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://eretancols.com/img/3.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://eretancols.com/img/4.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0px 0px 0px 0"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 300 18px/1.5 --fontFamily-sans"
						color="#474e53"
						text-align="left"
						max-width="640px"
					>
						А також регулярні воркшопи та семінари з питань харчування, психічного здоров'я та фізичного благополуччя є частиною нашої турботи про ваше здоров'я в цілому.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});